<template>
  <div class="content">
    <div class="img">
      <i class="fa fa-check-circle-o" :style="{ 'font-size': '7.2rem', color: '#19BE6B' }"></i>
    </div>
    <p class="success">提交成功</p>
    <p class="prompt">您可以点击-查看记录-查看历史提交信息</p>
  </div>
  <div class="buttonList">
    <router-link :to="{ path: `/submitRecord/list` }"><div class="lock">查看记录</div></router-link>
    <router-link :to="{ path: '/demandFormPhone' }"
      ><div class="continue">继续提交</div></router-link
    >
  </div>
</template>
<style lang="scss" scoped>
.content {
  background-color: #fff;
  padding-bottom: 5.8rem;
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    padding-top: 3.2rem;
  }
  p {
    text-align: center;
  }
  .prompt {
    padding-top: 0.5rem;
    color: #999999;
    font-size: 1.4rem;
  }
  .success {
    font-size: 2rem;
    color: #333333;
    font-weight: 700;
  }
}
.buttonList {
  width: 33.5rem;
  padding-top: 3rem;
  margin: 0 auto;
  .lock,
  .continue {
    display: block;
    height: 4.5rem;
    margin-right: 2rem;
    margin-bottom: 1.5rem;
    border-radius: 16.8rem;
    font-size: 1.6rem;
    font-family: '微软雅黑', sans-serif, '微软雅黑', sans-serif-400;
    font-weight: 400;
    line-height: 4.5rem;
    text-align: center;
    cursor: pointer;
  }
  .lock {
    background: #13c2c2;
    color: #fff;
  }
  .continue {
    color: #409eff;
    border: 1px solid #409eff;
  }
}
</style>
